import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { error } from 'src/app/_helpers/error';
import { AuthService } from '../../services/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  submitted: boolean = false;
  error = error.err;
  loginForm:FormGroup;
  hide = true;
  hide1 = true;
  hide2 = true;
  userType: any = 1;
  check: number = 1;
  adminLogo:any;
  @ViewChild('imageRef') imageRef: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private shared: SharedService
  ) { }



  forgetEmail:any;

  ngOnInit() {
    this.initForm();
    this.forgetEmail = JSON.parse(localStorage.getItem('receiveEmail'));
    this.loginForm.get('email').patchValue(this.forgetEmail);
    let currentUser = this.authService.currentUserValue;
    if(currentUser){
      this.router.navigateByUrl('/dashboard');
    }

    const id = this.route.snapshot?.params?.id;
    const  name= this.route.snapshot?.params?.name;
    if(id){
      this.getLogo(id);
      localStorage.setItem("currentUserId", id);
      localStorage.setItem("currentUserName", name);
    }else{
      localStorage.removeItem("currentUserId");
      localStorage.removeItem("currentUserName");
    }
   }

  get f() {
    return this.loginForm.controls;
  }

  getLogo(id:any){
    this.authService.getAdminLogo(id).subscribe((res:any)=>{
      this.adminLogo = res.data.logo.url;
      this.checkImage();
      localStorage.setItem('currentAdminLogo', JSON.stringify(this.adminLogo));
    })
  }

  checkImage(){
        const img = document.querySelector('.login-banner img')as HTMLImageElement ;
        console.log(this.imageRef.nativeElement.naturalWidth , this.imageRef.nativeElement.naturalHeight)
        if (this.imageRef.nativeElement.naturalWidth < 50 || this.imageRef.nativeElement.naturalHeight<70) {
            img.classList.remove('small-image');
            img.classList.add('medium-image');
        }else{
          img.classList.remove('small-image');
          img.classList.remove('medium-image');
        }
   }

  emailreg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  initForm(){
    this.loginForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email,Validators.pattern(this.emailreg)]),
      password: new FormControl('', [
        Validators.required
      ]),
    });
  }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid || this.loginForm.pristine) return;
    this.submitted = false;
    let user: any = {
      email: this.loginForm.value.email.toLowerCase(),
      password: this.loginForm.value.password,
      userType: "SUB_AD"
    };
    this.authService.login(user).subscribe(
      (res: any) => {
        if(res.type=='success') {
          let currentUserId = localStorage.getItem("currentUserId");
          if(res?.data?.userId== currentUserId || !currentUserId){
            this.shared.loadAdminLogo(true);
            localStorage.removeItem("receiveEmail");
            localStorage.setItem('currentUser', JSON.stringify(res.data));
            this.router.navigateByUrl('/dashboard');
            localStorage.removeItem('email');
            this.toastr.success("Login success");
          }else{
            this.toastr.error("Invalid email or password");
          }
        }
      },
      (err) => {
        this.toastr.error(err?.error?.message);
      }
    );
  }

  myFunction() {
    this.hide = !this.hide;
  }
}
