import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { HeaderService } from "../services/header.service";
import { AuthService } from "src/app/auth/services/auth.service";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { SharedService } from "../services/shared.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  welcomeUserMsg: boolean = false;
  dashboardHeading: boolean = false;
  changePasswordHeading: boolean = false
  buyLeadsHeading: boolean = false;
  tierdPlanHeading: boolean = false;
  serviceRequestHeading: boolean = false;
  whiteLabelHeading: boolean = false;
  serviceProvidersHeading: boolean = false;
  imgURL: any;
  key: any;
  profileURL: any;
  profileKey: any;

  @ViewChild("template") template: TemplateRef<any>;
  @ViewChild("template1") template1: TemplateRef<any>;
  modalRef: any;
  interestForm: FormGroup;

  constructor(
    private _header: HeaderService,
    public router: Router,
    private modalService: BsModalService,
    private authService: AuthService,
    private shared: SharedService,
    private toast: ToastrService,
    private fb: FormBuilder,
  ) {

    this._header.welcomeUserMsg.subscribe((res) => {
      this.welcomeUserMsg = res;
    });

    this._header.dashboardHeading.subscribe((res) => {
      this.dashboardHeading = res;
    });

    this._header.changePasswordHeading.subscribe((res) => {
      this.changePasswordHeading = res;
    });

    this._header.buyLeadsHeading.subscribe((res) => {
      this.buyLeadsHeading = res;
    });

    this._header.tierdPlanHeading.subscribe((res) => {
      this.tierdPlanHeading = res;
    });

    this._header.serviceRequestHeading.subscribe((res) => {
      this.serviceRequestHeading = res;
    });

    this._header.whiteLabelHeading.subscribe((res) => {
      this.whiteLabelHeading = res;
    });

    this._header.serviceProvidersHeading.subscribe((res) => {
      this.serviceProvidersHeading = res;
    });



  }
  currentUser: any;
  ngOnInit(): void {
    this.initForm();
    this.currentUser = this.authService.currentUserValue;
  }

  initForm() {
    this.interestForm = new FormGroup({
      email: new FormControl({ value: '', disabled: true }),
      myFile: new FormControl({ value: '', disabled: true }),
      key: new FormControl({ value: '', disabled: true }),
      firstName: new FormControl("", [Validators.required, Validators.minLength(3)]),
      lastName: new FormControl("", [Validators.required, Validators.minLength(3)]),
      phoneNumber: new FormControl("", [Validators.minLength(7), Validators.required]),
      zips: this.fb.array([this.multiDomains()])
    });
  }

  get f() {
    return this.interestForm.controls;
  }

  multiDomains(): FormGroup {
    return this.fb.group({
      zip: new FormControl("", [Validators.required, Validators.minLength(5)])
    })
  }

  zips(): FormArray {
    return this.interestForm.controls['zips'] as FormArray
  }

  get items(): FormArray {
    return this.interestForm.get('zips') as FormArray;
  }

  removeDomain(e: any) {
    this.zips().removeAt(e);
  }

  addDomain() {
    this.zips().push(this.multiDomains());
  }

  logout() {
    this.modalRef.hide();

    let id = localStorage.getItem("currentUserId");
    let name = localStorage.getItem("currentUserName");
    if(id && name){
      this.router.navigateByUrl(`${id}/${name}/auth/login`);
    }else{
      this.router.navigateByUrl(`/auth`);
    }
    localStorage.clear();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  confirm() {
    this.logout();
  }
  cancel() {
    this.modalRef.hide();
  }

  openLogoutModal() {
    this.openModal(this.template);
  }

  openViewProfileModal() {
    this.getUserDetails();
  }

  getUserDetails() {
    this.items.clear();
    this.interestForm.reset();
    this.shared.getUserDetails(this.currentUser.userId).subscribe((res: any) => {
      this.interestForm.get("email").patchValue(res.data.email);
      this.interestForm.get("firstName").patchValue(res.data.firstName);
      this.interestForm.get("lastName").patchValue(res.data.lastName);
      this.interestForm.get("phoneNumber").patchValue(res.data.phoneNumber);
      this.imgURL = res?.data?.logo?.url;
      this.key = res?.data?.logo?.key;
      this.profileURL = res?.data?.profileImage?.url;
      this.profileKey = res?.data?.profileImage?.url;
      res.data.zipCodes.forEach((user: any) => {
        this.zips().push(
          this.fb.group({
            zip: [{ value: user, disabled: true }, [Validators.required, Validators.minLength(5)]],
          })
        )
      })
      this.openModal(this.template1);
    });
  }

  submitted: boolean = false;
  edit() {
    this.interestForm.get('firstName').patchValue(this.interestForm.value.firstName?.trim());
    this.interestForm.get('lastName').patchValue(this.interestForm.value.lastName?.trim());
    this.submitted = true;
    if (this.interestForm.invalid) return
    this.submitted = false;
    let req: any = {
      "email": this.interestForm.value.email,
      "firstName": this.interestForm.value.firstName,
      "lastName": this.interestForm.value.lastName,
      "phoneNumber": this.interestForm.value.phoneNumber,
      "profileImage": {
        "url": this.profileURL,
        "key": this.profileKey
      },
      "logo": {
        "url": this.imgURL,
        "key": this.key
      },
    }
    this.shared.updateAdmin(this.currentUser.userId, req).subscribe((res: any) => {
      if (res.type == "success") {
        this.toast.success(res.message)
        this.modalRef.hide();
        this.shared.loadAdminLogo(true);
      }
    })
  }

  numberOnly(evt: any) {
    let charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      return true
    } else {
      return false;
    }
  }

  reset() {
    this.interestForm.reset();
  }

  charOnly(evt: any) {
    let charCode = evt.which ? evt.which : evt.keyCode;
    console.log(charCode)
    if (charCode >= 65 && charCode <= 90 || charCode >= 97 && charCode <= 122 || charCode == 32) {
      return true
    } else {
      return false;
    }
  }

  onClickImage(e: any) {
    document.getElementById(e).click();
  }

  onChangeimg(e: any, type: any) {
    let mimeType = e.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.toast.error('Only allow this types of images .jpg, .jpeg, .png');
      return;
    }
    let file = e.target.files[0];
    let fd = new FormData();
    fd.append('file', file);
    if (e.target.files && e.target.files.length) {
      this.shared.uploadImage(fd)
        .subscribe((res: any) => {
          let reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = (_event) => {
            if (type == 'logo') {
              this.imgURL = res.data.fileUrl;
              this.key = res.data.key;
            } else {
              this.profileURL = res.data.fileUrl;
              this.profileKey = res.data.key;
            }
          }
        })
    }
  }
}
