<app-layout></app-layout>
<div class="content">
    <div class="mt-4">
        <div class="total-counts row" style="margin-left: 0px;">

            <div class="total-count total-careers col-lg-3 mt-3 card-border" routerLink="/service-providers/list">
                <img src="../../../assets/dashboard_icons/Active users.svg" alt="" height="60" width="60">
                <div>
                    <h2 id="totalUsers" class="counter">{{analyticsData?.serviceProviderInquiryCount ? analyticsData?.serviceProviderInquiryCount : 0}}
                    </h2>
                    <p>Registered Service Providers</p>
                </div>
            </div>

            <div class="total-count total-resources col-lg-3 mt-3 card-border" routerLink="/buy-leads/list">
                <img src="../../../assets/dashboard_icons/View Feedback.svg" alt="" height="60" width="60">
                <div>
                    <h2 id="client" class="counter">{{analyticsData?.qbCount ? analyticsData?.qbCount : 0}}</h2>
                    <p>Quick Buy Leads </p>
                </div>
            </div>

            <div class="total-count total-resources col-lg-3 mt-3 card-border" routerLink="/real/estate/leads/list">
                <img src="../../../assets/dashboard_icons/View Feedback.svg" alt="" height="60" width="60">
                <div>
                    <h2 id="client" class="counter">{{analyticsData?.tpCount ? analyticsData?.tpCount : 0}}</h2>
                    <p>Real Estate Leads </p>
                </div>
            </div>
        </div>
    </div>
</div>