<div id="auth-width">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        <div class="login-banner">
          <img #imageRef [src]="adminLogo ? adminLogo : '../../../../assets/images/Home_Seller.svg'" alt="login-banner" class="img-fluid">
        </div>
      </div>
      <div class="col-md-6">
        <div class="login-field">
          <div class="login-box">
            <h3>Sign In</h3>
            <form [formGroup]="loginForm" (submit)="login()">
              <div class="form-group">
                <label for="email">Email Address <span class="require">*</span></label>
                <span class="input-icon"><img src="../../../../assets/icons/email.svg" alt=""
                    style="margin-top: -10px;"></span>
                <input type="email" class="form-control" placeholder="Email" id="email" formControlName="email">
                <div *ngIf="f.email.touched && f.email.errors" class="alert alert-danger">
                  <span *ngIf="f.email.errors.required">
                    Email Required
                  </span>
                  <span *ngIf="f.email.errors.email || f.email.errors.pattern">
                    Invalid Email
                  </span>
                </div>
              </div>
              <div class="form-group"> 
                <label for="password">Password <span class="require">*</span></label>
                <span class="input-icon"><img src="../../../../assets/icons/password.svg" alt=""
                  style="margin-top: -10px;"></span>
              <input [type]="hide ? 'password' : 'text'" class="form-control" placeholder="Password" id="password"
                formControlName="password">
              <mat-icon class="placeholder" (click)="myFunction()">{{hide ? 'visibility_off' :
                'visibility'}}</mat-icon>
                  <div *ngIf="f.password.touched && f.password.errors" class="alert alert-danger">
                    <span *ngIf="f.password.errors.required">
                      Password Required
                    </span>
                    <span *ngIf="f.password.errors.minlength">
                      Password must be of six or more characters
                    </span>
                </div>

                <span class="forgot-pass text-right mt-2" style="float: right;" routerLink="/auth/forgot-password">Forgot Password?</span>
              </div>

              <button type="submit" class="btn btn-primary">Sign In</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>