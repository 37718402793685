import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class WhiteLabelService {

  url: any = environment.API_URL;


  constructor(
    private http: HttpClient
  ) { }
  
  getLabelList(data: any) {
    return this.http.post<any>(this.url + 'whitelist/list', data);
  }

  addLabel(data: any) {
    return this.http.post(this.url + 'whitelist/add', data);
  }

  updateLabel(id:any,data: any) {
    return this.http.put(this.url + `whitelist/update/${id}`, data);
  }

  deleteLabel(id: any) {
    return this.http.delete(this.url + `whitelist/delete/${id}`);
  }
}
