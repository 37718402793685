import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private shared: SharedService
  ) { }
  
  modalRef: any;
  currentUser:any;
  currentAdminLogo:any;

  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
    this.getLogo(this.currentUser?.userId)
    let currentAdminLogo = localStorage.getItem("currentAdminLogo");
    if(currentAdminLogo){
      this.currentAdminLogo = JSON.parse(currentAdminLogo);
    }
    this.shared.loadLogo$.subscribe((res:any)=>{
      this.getLogo(this.currentUser?.userId)
    })
  }

  getLogo(id:any){
    this.authService.getAdminLogo(id).subscribe((res:any)=>{
      this.currentAdminLogo = res.data.logo.url;
      localStorage.setItem('currentAdminLogo', JSON.stringify(this.currentAdminLogo));
    })
  }

  ngDoCheck(){
  
    if(window.location.pathname.indexOf('coupon') != -1){
      document.getElementById('couponmenu').scrollIntoView();
    }else if(window.location.pathname.indexOf('coordinates') != -1){
      document.getElementById('Coordinates').scrollIntoView();
    }else if(window.location.pathname.indexOf('event-sponsors') != -1){
      document.getElementById('Sponsors').scrollIntoView();
    }else if(window.location.pathname.indexOf('treasure') != -1){
      document.getElementById('Treasure').scrollIntoView();
    }else if(window.location.pathname.indexOf('more-sections') != -1){
      document.getElementById('More-Section').scrollIntoView();
    }else if(window.location.pathname.indexOf('home-partners') != -1){
      document.getElementById('home-partners').scrollIntoView();
    }
  

  }

  disabledTab(objEvent) {
    console.log(objEvent.keyCode+"sidbar");
  if (objEvent.keyCode == 9) {  //tab pressed
      objEvent.preventDefault(); // stops its action
  }
}

  
}
