import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/shared/services/header.service';
import { DashboardService } from '../services/dashboard.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import * as moment from 'moment';
import * as $ from "jquery";
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  currentUser: any;
  analyticsData: any;
  constructor(
    private _header: HeaderService,
    private dashboardService: DashboardService,
    private authService: AuthService,
    private shared: SharedService
  ) { }

  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 1 Year': [moment().year(moment().year() - 1).startOf('year'), moment().year(moment().year() - 1).endOf('year')],
  }

  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some(d => d.isSame(m, 'day'))
  }

  adminLogo:any;

  ngOnInit(): void {
    this._header.welcomeUserMsg.next(true);
    this.currentUser = this.authService.currentUserValue;
    this.calanderRemoveDeleteIcon();
    this.getDashboardData();
    this.getUserDetails(this.currentUser?.userId);
  }

  calanderRemoveDeleteIcon(){
    $("#dateDange123").click(function () {
      $(".btn-default").remove();
    })
  }

  getDashboardData(){
    this.dashboardService.getAnalytics().subscribe((res: any) => {
      if(res.type =='success'){
        this.analyticsData = res.data;
      }
    });
  }

  getUserDetails(userId:any){
    this.shared.getUserDetails(userId).subscribe((res:any) => {
      localStorage.setItem('subAdminDetails', JSON.stringify(res.data))
    });
  }

  ngOnDestroy() {
    this._header.welcomeUserMsg.next(false);
  }
}
