import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Subject } from "rxjs";

@Injectable()
export class SharedService {
  url: any = environment.API_URL;
  constructor(private http: HttpClient) {}

  private loadLogo = new Subject<any>();
  loadLogo$ = this.loadLogo.asObservable();

  loadAdminLogo(data: any) {
    this.loadLogo.next(data);
  }

  uploadImage(file: any) {
    return this.http.post(this.url + "file/upload", file);
  }

  getUserDetails(id:any){
    return this.http.get(this.url + `admin-user/details/${id}`);
  }

  getUserLeads(leadType:any,data:any){
    return this.http.get(this.url + `user/lead-list?leadType=${leadType}&page=${data.page}&limit=${data.limit}`);
  }

  deleteLead(id:any){
    return this.http.delete(this.url + `user/delete-lead/${id}`);
  }

  updateAdmin(id:any,data: any) {
    return this.http.put(this.url + "admin-user/update/"+id, data);
  }

  getLeadById(id: any) {
    return this.http.get<any>(this.url + `user/lead-details/${id}`);
  }
}
