import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ServiceProvidersService {

  url: any = environment.API_URL;


  constructor(
    private http: HttpClient
  ) { }
  
  getServicePrividersList(data: any) {
    return this.http.get<any>(this.url + `user/sp-list?page=${data.page}&limit=${data.limit}`);
  }

  getFaqById(data: any) {
    return this.http.get<any>(this.url + 'faq/lists?id=' + data.id + '&type=' + data.type+  '&languageId=' + data.languageId);
  }

  addFaq(data: any) {
    return this.http.post(this.url + 'faq/create', data);
  }

  updateServiceProvider(id:any,data: any) {
    return this.http.put(this.url + `user/update-sp/${id}`, data);
  }

  deleteServiceProvider(id: any) {
    return this.http.delete(this.url + `user/delete-sp/${id}`);
  }

  checkZipCOde(data: any) {
    return this.http.post(this.url + "admin-user/is-zip-code-assigned", data);
  }
}
