import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {

  submitted: boolean = false;
  modalRef: BsModalRef;
  hide:boolean = true;
  userToken:any;



  @ViewChild("template") template: TemplateRef<any>;
  constructor(
    private authService: AuthService,
    private router: Router,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.userToken = params['token'];
      this.verifyToken(this.userToken);
    })
  }

  verifyToken(token:any){
    this.authService.verifyPasswordToken({token: token}).subscribe((res:any)=>{
    },(err)=>{
      this.toastr.error(err.error.message);
      this.router.navigateByUrl('/');
    })
  }

  passwordRegex = /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%&*])[\w !@#$%&*]/;
  resetForm = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.minLength(8),Validators.pattern(this.passwordRegex)])
  })

  get f() {
    return this.resetForm.controls;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }


  sendEmailToLogin:any;

  resetPassword() {
    this.submitted = true;
    if (this.resetForm.invalid || this.resetForm.pristine) return;
    this.submitted = false;

    this.authService.resetPassword({token: this.userToken, password:this.resetForm.value.password})
    .subscribe((res: any) => {
      if (res.type=='success') {
        this.toastr.success(res.message);
        localStorage.clear();
        this.router.navigateByUrl('/');
      }else{
        this.toastr.error(res.message)
      }
    },(err)=>{
      this.toastr.error(err.error.message);
    })
  }

  OkPress(){
    this.modalRef.hide();
    this.router.navigateByUrl('/email-send');
  }

  myFunction(){
    this.hide = !this.hide;
  }

}
