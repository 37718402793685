import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { AuthService } from '../auth/services/auth.service';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";
import { tap } from 'rxjs/operators';
import { ToastrService } from "ngx-toastr";



@Injectable()

export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private router: Router,
        private toastrService: ToastrService

    ) { }

    currentUser:any;


    ngOnInit(): void {
            this.currentUser = this.authService.currentUserValue;
    }

    

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const basicAuthDetails = this.authService.basicAuthentication;
        const currentUser = this.authService.currentUserValue;
        if(currentUser){
            request = request.clone({
                setHeaders: {
                    'accessToken': `Bearer ${currentUser.token}`,
                    // 'Authorization': 'Basic ' + window.btoa(basicAuthDetails.userName + ':' + basicAuthDetails.password)
                }
            })
        }else{
            request = request.clone({
                setHeaders: {
                    'Authorization': 'Basic ' + btoa(basicAuthDetails.userName + ':' + basicAuthDetails.password)
                }
            })
        }

        return next.handle(request).pipe(
            tap(
                event => {
                    if (event instanceof HttpResponse) {
                        if (event.body.status == 401) {
                            alert(1)
                            this.router.navigate(['/auth/login']);
                            this.toastrService.error('You are already logged in with different device');
                        }
                    }
                },
                error => {
                    let id = localStorage.getItem("currentUserId");
                    let name = localStorage.getItem("currentUserName");

                    if(error?.status==409){
                        this.router.navigateByUrl(`${id}/${name}/auth/login`);
                        localStorage.clear();
                        this.toastrService.error(error?.error?.message)
                    }

                    if (error.status == 401) {
                        if(error?.error?.message=='Token expired'){
                            this.router.navigateByUrl(`${id}/${name}/auth/login`);
                            localStorage.clear();
                        }
                        this.toastrService.error(error?.error?.message)
                    }
                    if (error.status == 503) {
                        if (error.error.errorCode == 101 || error.error.errorCode == 102 || error.error.errorCode == 104) {
                            this.toastrService.error(error?.error?.output?.messageData?.en)
                            this.router.navigateByUrl(`${id}/${name}/auth/login`);
                            localStorage.clear();
                        }
                    }
                }
            )
        );
    }
}