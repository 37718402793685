import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { ViewsComponent } from "./views/views.component";
import { AuthGuard } from "./_helpers/auth.guard.service";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";

const routes: Routes = [

  {
    path: ":id/:name/auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },

  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },

  { path: "", redirectTo: "auth", pathMatch: "full" },
  {
    path: "edit-profile",
    component: EditProfileComponent,
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
  },
  {
    path: "",
    component: ViewsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "change-password", component: EditProfileComponent },

      {
        path: "dashboard",
        loadChildren: () =>
        import("../app/dashboard/dashboard.module").then(
          (m) => m.DashboardModule
        )
      },

      {
        path: "buy-leads",
        loadChildren: () =>
        import("../app/views/buy-leads/buy-leads.module").then(
          (m) => m.BuyLeadsModule
        )
      },

      {
        path: "real/estate/leads",
        loadChildren: () =>
        import("../app/views/tired-plan/tired-plan.module").then(
          (m) => m.TiredPlanModule
        )
      },

      {
        path: "service-request",
        loadChildren: () =>
        import("../app/views/service-request/service-request.module").then(
          (m) => m.ServiceRequestModule
        )
      },

      {
        path: "white-label",
        loadChildren: () =>
        import("../app/views/white-label/white-label.module").then(
          (m) => m.WhiteLabelModule
        )
      },

      {
        path: "service-providers",
        loadChildren: () =>
        import("../app/views/service-providers/service-providers.module").then(
          (m) => m.ServiceProvidersModule
        )
      }

    ],
  },
  { path: '**', redirectTo: 'auth', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
