<div class="sidebar" (keydown)="disabledTab($event)">
  <div class="logo"><img [src]="currentAdminLogo ? currentAdminLogo : '../../../assets/images/Home_Seller.svg'" alt="logo"></div>
  <div class="header-links">

    <a routerLink="/dashboard" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/dashboard-black.svg" alt="" height="24"
          width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/White/dashboard-white.svg" alt="" height="24"
          width="24">
      </span>Dashboard</a>

    <a routerLink="/buy-leads/list" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/buy-leads-black.svg" alt="" height="24"
          width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/White/buy-leads-white.svg" alt="" height="24"
          width="24">
      </span>QuickBuy Leads</a>

    <a routerLink="/real/estate/leads/list" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/leads-black.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/White/leads-white.svg" alt="" height="24" width="24">
      </span>Real Estate Leads</a>

    <a routerLink="/service-request/list" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/enquiry-black.svg" alt="" height="24"
          width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/White/enquiry-white.svg" alt="" height="24"
          width="24">
      </span>Service Providers Inquiries</a>

    <a routerLink="/service-providers" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/service-provider-black.svg" alt="" height="24"
          width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/White/service-provider-white.svg" alt="" height="24"
          width="24">
      </span>Manage Service Providers</a>
  </div>
</div>