<div class="header">
    <div class="left-sec">
        <ng-container *ngIf="welcomeUserMsg">
            <h1>Dashboard</h1>
        </ng-container>
        <h1 *ngIf="dashboardHeading">Dashboard</h1>
        <h1 *ngIf="changePasswordHeading">Change Password</h1>
        <h1 *ngIf="buyLeadsHeading">QuickBuy Leads</h1>
        <h1 *ngIf="tierdPlanHeading">Real Estate Leads</h1>
        <h1 *ngIf="serviceRequestHeading">Service Providers Inquiries</h1>
        <h1 *ngIf="whiteLabelHeading">White Label</h1>
        <h1 *ngIf="serviceProvidersHeading">Manage Service Providers</h1>
    </div>


    <div class="right-sec">
        <div class="btn-group" dropdown placement="bottom right">
            <button id="button-alignment" dropdownToggle type="button" class="btn dropdown-toggle"
                aria-controls="dropdown-alignment"><mat-icon>settings</mat-icon> Settings
            </button>
            <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                aria-labelledby="button-alignment">
                <li style="cursor: pointer;" role="menuitem"><a class="dropdown-item admin-dropdown"
                        (click)="openViewProfileModal()">View Profile</a></li>
                <li role="menuitem"><a class="dropdown-item admin-dropdown" routerLink="/edit-profile">Change
                        Password</a></li>
                <li role="menuitem"><a class="dropdown-item admin-dropdown" (click)="openLogoutModal()">Logout</a></li>
            </ul>
        </div>

    </div>
</div>

<ng-template #template1>
    <div class="modal-header" style="background-color: white;">
        <h3 style="font-size: 22px;font-weight: 500;padding-top: 9px;">Update Profile</h3>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide(); reset()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body delete-modal" style="max-height: 450px;overflow: auto;">
        <div class="partner-form custom-form-field-container">
            <form [formGroup]="interestForm" class="row g-3 form-group" style="margin-top: 38px;">
                <div class="col-md-8">
                    <div style="display: flex;justify-content: space-evenly;">
                        <div>
                            <mat-label class="text-label">Logo</mat-label>
                            <img [src]="imgURL" alt="" width="165" height="120" (click)="onClickImage('myFile2')" *ngIf="imgURL" style="margin-top: 0px !important;">
                            <input #myFile2 type="file" name="myFile2" id="myFile2"
                                (change)="onChangeimg($event, 'logo')" accept="image/*" style="display: none;" />
                            <div class="edit-btn">
                                <img src="../../../../../assets/icons/edit.svg" (click)="onClickImage('myFile2')" alt="edit" height="35" width="35">
                            </div>
                        </div>

                        <div style="margin-left: 48%;">
                            <mat-label class="text-label">Profile</mat-label>
                            <img [src]="profileURL" alt="" width="120" height="120" (click)="onClickImage('myFile3')" *ngIf="profileURL" style="margin-top: 0px !important;">
                            <input #myFile2 type="file" name="myFile3" id="myFile3"
                                (change)="onChangeimg($event, 'profile')" accept="image/*" style="display: none;" />
                            <div class="edit-btn2">
                                <img src="../../../../../assets/icons/edit.svg" (click)="onClickImage('myFile3')" alt="edit" height="35" width="35">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 mt-4">
                    <mat-form-field class="example-full-width inputboxs" appearance="outline">
                        <mat-label>Email</mat-label>
                        <input type="text" matInput placeholder="Email" formControlName="email">
                    </mat-form-field>
                </div>
                <div class="col-md-8">
                    <mat-form-field class="example-full-width inputboxs" appearance="outline">
                        <mat-label>First Name</mat-label>
                        <input type="text" matInput placeholder="First Name" formControlName="firstName" maxlength="32" (keypress)="charOnly($event)">
                        <div *ngIf="submitted && f.firstName.errors">
                            <mat-error *ngIf="f.firstName.errors?.required">First name is required.</mat-error>
                            <mat-error *ngIf="f.firstName.errors?.minlength">First name must have atleast 3 characters.</mat-error>
                        </div>
                    </mat-form-field>
                </div>
                <div class="col-md-8">
                    <mat-form-field class="example-full-width inputboxs" appearance="outline">
                        <mat-label>Last Name</mat-label>
                        <input type="text" matInput placeholder="Last Name" formControlName="lastName" maxlength="32" (keypress)="charOnly($event)">
                        <div *ngIf="submitted && f.lastName.errors">
                            <mat-error *ngIf="f.lastName.errors?.required">Last name is required.</mat-error>
                            <mat-error *ngIf="f.lastName.errors?.minlength">Last name must have atleast 3 characters.</mat-error>
                        </div>
                    </mat-form-field>
                </div>
                <div class="col-md-8">
                    <mat-form-field class="example-full-width inputboxs" appearance="outline">
                        <mat-label>Phone Number</mat-label>
                        <input type="text" matInput placeholder="Phone Number" maxlength="15" (keypress)="numberOnly($event)" formControlName="phoneNumber">
                        <div *ngIf="submitted && f.phoneNumber.errors">
                            <mat-error *ngIf="f.phoneNumber.errors?.required">Phone is required.</mat-error>
                            <mat-error *ngIf="f.phoneNumber.errors?.minlength">Phone Number must have atleast 7 digits.</mat-error>
                        </div>
                    </mat-form-field>
                </div>

                <div class="col-md-8" formArrayName="zips">
                    <div *ngFor="let i of interestForm.get('zips')['controls']; let x=index"
                        [formGroup]="i" style="margin-bottom: 1rem !important;">
                        <mat-form-field class="example-full-width inputboxs" appearance="outline">
                            <mat-label>Zip Code</mat-label>
                            <input type="text" matInput placeholder="Zip" maxlength="9" formControlName="zip">
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>

        <div class="modal-btn d-flex justify-content-around mt-3" style="padding-bottom: 0px !important;margin-top: -22px !important;">
            <button class="btn btn-cancel" (click)="cancel()">Cancel</button>
            <button class="btn btn-blue" (click)="edit()">Update User</button>
        </div>
    </div>

</ng-template>

<ng-template #template>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center delete-modal align-items-center">
        <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
        <p>Are you sure you <br>want to logout?</p>
        <div class="modal-btn d-flex justify-content-around">
            <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
            <button class="btn btn-blue" (click)="confirm()">Yes</button>
        </div>
    </div>
</ng-template>