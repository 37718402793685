import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  manageContractorHeading = new BehaviorSubject(false);
  manageFeedsHeading = new BehaviorSubject(false);
  welcomeUserMsg = new BehaviorSubject(false);
  dashboardHeading =  new BehaviorSubject(false);
  changePasswordHeading = new BehaviorSubject(false);
  buyLeadsHeading= new BehaviorSubject(false);
  tierdPlanHeading= new BehaviorSubject(false);
  serviceRequestHeading= new BehaviorSubject(false);
  whiteLabelHeading= new BehaviorSubject(false);
  serviceProvidersHeading= new BehaviorSubject(false);
}
